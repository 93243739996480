import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { ROUTES } from '../parameters/routes';
import Login from '../routes/user-management/login/Login';
import ForgotPassword from "../routes/user-management/login/ForgotPassword";
import Events from "../routes/core/events/Events";
import Users from "../routes/core/users/Users";
import Layout from "./appComponents/Layout";
import Firms from "../routes/core/firms/Firms";
import Profile from "../routes/core/profile/Profile";
import Approvals from "../routes/core/approval/Approvals";
import Dashboard from "../routes/core/dashboard/Dashboard";
import Management from "../routes/core/Management/Management";
import Documents from "../routes/core/documents/documents";
import FirmsProfessionals from "../routes/core/firmsProfessionals/FirmsProfessionals";
import News from "../routes/core/news/News";
import NotFound from "../routes/core/NotFound/NotFound";
import DocumentDetails from "../routes/core/documents/DocumentDetails/DocumentDetails";

const AppRouter = () => {
    return (
            <Switch>
                <Route path={ROUTES.login} exact component={Login}/>
                <Route path={ROUTES.forgotPassword} exact component={ForgotPassword}/>
                <Route>
                    <Layout>
                        <Switch>
                            <Route path={ROUTES.home}  component={Profile} />
                            <Route path={ROUTES.users} exact component={Users}/>
                            <Route path={ROUTES.firms} exact component={Firms}/>
                            <Route path={ROUTES.professionalsFirm} exact component={FirmsProfessionals}/>
                            <Route path={ROUTES.news} exact component={News}/>
                            <Route path={ROUTES.events} exact component={Events}/>
                            <Route path={ROUTES.documents} exact component={Documents}/>
                            <Route path={ROUTES.document} exact={true} children={<DocumentDetails/>} />
                            <Route path={ROUTES.approvals} exact component={Approvals}/>
                            <Route path={ROUTES.dashboard} exact component={Dashboard}/>
                            <Route path={ROUTES.management} exact component={Management}/>
                            <Route path='*' component={NotFound}/>
                        </Switch>
                    </Layout>
                </Route>
                <Route render={() => <Redirect to={ROUTES.notFound} />} />
                <Route path='*' exact={true} component={NotFound}/>
            </Switch>
    );
};

export default AppRouter;